import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";

const StyledRelatedResources = styled.aside`
  border-top: 1px solid var(--light-blue-mid);
  width: 100%;
  ${baseGridStyles}
  padding: 0;
  align-items: start;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    border-top: 0;
    margin-top: 0;
    display: block;
    grid-column: 9 / 13;
    grid-template-columns: repeat(4,1fr);
  }

  h2 {
    grid-column: span 4;
    text-align: center;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-top: 2.4rem;
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 0 1.6rem;
      text-align: left;
      grid-column: span 4;
    }
  }
`

const RelatedResourceList = styled.ol`
  ${baseGridStyles}
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(4,1fr);
    grid-column: span 4;
  }
`

const RelatedResourceItem = styled.li`
  grid-column: span 4;
  border-bottom: 1px dotted var(--light-blue-mid);
  padding-bottom: 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 4;
  }
  
  a,
  a:hover,
  a:focus {
    border-bottom: 0;
  }
  a:hover,
  a:focus {
    border-bottom: 0;
    h3 {
      color: var(--light-blue-dark);
    }
  }
`

export { StyledRelatedResources, RelatedResourceList, RelatedResourceItem }
