import styled from "styled-components"
import { regular16, semibold16 } from "../../styledComponents/typography"
import breakpoints from "../../styledComponents/breakpoints"

const StyledSecondaryBlogCard = styled.article`
  a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: center;
  }
  p {
    margin: 0;

    padding-bottom: 1.6rem;
  }
  h3 {
    ${semibold16}
  }
`

const ResourceWrapper = styled.div`
  
  max-width: calc(100% - (101px + 32px));
`

const ResourceHeader = styled.h3`
  color: var(--grey-darker);
  margin: 0 0 0 1.6rem;
  ${regular16}
  color: var(--light-blue-darkest);

  a {
    text-decoration: none;
  }
`

const ResourceFigure = styled.figure`
  margin: 0;
  padding: 0;
  display: inline-flex;
  & .gatsby-image-wrapper {
    width: 6.4rem;
    height: 6.4rem;
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    & .gatsby-image-wrapper {
      width: 8.8rem;
      height: 8.8rem;
    }

    &:first-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const ResourceFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
`

export {
  StyledSecondaryBlogCard,
  ResourceWrapper,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
}
