import React from "react"
import SecondaryBlogCard from "../components/BlogCards/SecondaryBlogCard"
import {
  StyledRelatedResources,
  RelatedResourceList,
  RelatedResourceItem,
} from "../styledComponents/styledRelatedResources"

const RelatedResources = ({ relatedPosts }) => {
  return (
    <>
      <StyledRelatedResources>
        <h2>Other Blogs</h2>
        <RelatedResourceList>
          {relatedPosts.nodes.map(post => (
            <RelatedResourceItem key={post.id}>
              <SecondaryBlogCard
                title={post.title}
                featuredImage={post.featuredImage}
                uri={post.uri}
              />
            </RelatedResourceItem>
          ))}
        </RelatedResourceList>
      </StyledRelatedResources>
    </>
  )
}

export default RelatedResources
