import React from "react"
import { graphql } from "gatsby"
import parse, { domToReact } from "html-react-parser"

import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
  ArticleAuthorInfo,
} from "../styledComponents/article"
import RelatedResources from "../components/RelatedResources"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ 
  data: { post, relatedPostsByTag },
  location,
}) => {
  // const featuredImage = {
  //   fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
  //   alt: post.featuredImage?.node?.alt || ``,
  // }

  const replaceSize = {
    replace: ({ attribs, children }) => {
      if ( attribs?.class?.split(" ").includes("inline-gatsby-image-wrapper")
      ) {
        return (
          <span {...attribs} style={{width:"100%", height:"100%"}}>
            {domToReact(children)}
          </span>
        )
      }
    }
  }

  return (
    <Layout displayNavBorder>
      <SEO title={post.title} description={post.excerpt.replace('<p>','').replace('</p>', '')} />
      
      <Article
        className="resource-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <ArticleHeader>
          <h1 itemProp="headline">{parse(post.title)}</h1>
          <ArticleDate pubdate dateTime={post.dateGmt}>{post.date}</ArticleDate>
          <ArticleAuthorInfo className="author"><span>Published by</span> <a href="/team" rel="author" title={post.date}>The KaarmiBeing team</a></ArticleAuthorInfo>
        </ArticleHeader>

        {!!post.content && (
          <ArticleSection itemProp="articleBody">
            {parse(post.content, replaceSize)}
          </ArticleSection>
        )}

        {(relatedPostsByTag.nodes.length && (
          <RelatedResources relatedPosts={relatedPostsByTag} />
        )) ||
          ""}
      </Article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $tagName: String!
    $id: String!
  ) {

    relatedPostsByTag: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 5
      skip: 0
      filter: {
        tags: { nodes: { elemMatch: { name: { eq: $tagName } } } }
        id: { nin: [$id] }
      }
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
    # selecting the current post by id
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      dateGmt

      tags {
        nodes {
          name
          id
          uri
        }
      }
    }
  }
`
